import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-tailwind/react";
import { API_URL } from "lib/network/api";
import CardFaq from "./CardFaq";

const ListFaq = () => {
  const [dataFaq, setDataFaq] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFaq = () => {
      setLoading(true);
      axios
        .get(`${API_URL}/faqs`)
        .then((res) => {
          setDataFaq(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    fetchFaq();
  }, []);

  if (loading) {
    return (
      <div className="my-16 mx-auto container md:px-0 px-4">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-semibold text-slate-900 tracking-tight dark:text-slate-200">
            Frequently Asked Question
          </h2>
        </div>
        <div className="flex flex-col gap-6 mt-10">
          {[...Array(3)].map((i, idx) => {
            return (
              <Typography
                key={idx}
                as="div"
                variant="h1"
                className="mb-4 h-[150px] rounded-[26px] bg-gray-300"
              >
                &nbsp;
              </Typography>
            );
          })}
        </div>
      </div>
    );
  }

  if (!dataFaq.length) {
    return <></>;
  }

  return (
    <div className="my-16 mx-auto container max-w-6xl md:px-0 px-6">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-semibold text-slate-900 tracking-tight dark:text-slate-200">
          Frequently Asked Question
        </h2>
      </div>
      <div className="flex flex-col gap-6 mt-10">
        {dataFaq.map((i, idx) => {
          return <CardFaq key={idx} item={i} />;
        })}
      </div>
      {dataFaq.length > 2 && (
        <div className="flex justify-center items-center mt-6">
          <Link to="/faq">
            <Button
              size="lg"
              className="bg-primary text-white flex items-center py-3 px-7 font-poppins font-medium rounded-xl text-sm tracking-wide shadow-none"
            >
              See More Faq
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ListFaq;
