import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogHeader,
  DialogFooter,
  Button,
} from "@material-tailwind/react";

const SectionHeader = ({ title, action }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleOpen = () => {
    setModalShow(!modalShow);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <Typography variant="h2" className="text-xl">
          {title}
        </Typography>
        {action && (
          <div
            className="rounded-xl bg-white border-2 border-secondary px-4 lg:px-6 py-2 flex flex-row items-center gap-3 cursor-pointer"
            onClick={() => handleOpen()}
          >
            <Typography variant="small" className="text-sm font-semibold">
              APPLY WITH
            </Typography>
            <img
              className="h-5 w-auto mt-1"
              src="/assets/singpass.png"
              alt=""
            />
          </div>
        )}
      </div>
      <Dialog
        open={modalShow}
        handler={handleOpen}
        size="sm"
        className="p-7 rounded-2xl"
      >
        <DialogHeader>
          <div className="relative w-full items-center flex flex-col justify-center">
            <img className="h-12 w-auto" src="/assets/singpass.png" alt="" />
            <Typography className="mt-6 text-center font-medium mx-auto">
              Apply with Singpass and increase your <br /> chance of getting a
              loan by 40%.
            </Typography>
          </div>
        </DialogHeader>
        <DialogFooter className="flex flex-col gap-4">
          <Button
            className="bg-primary font-normal w-full py-3.5 tracking-wider text-sm rounded-2xl opacity-1 shadow-gray-900/50"
            onClick={handleOpen}
          >
            APPLY WITH SINGPASS
          </Button>
          <Button
            className="text-primary border-2 border-primary bg-white font-medium w-full py-3.5 tracking-wider text-sm rounded-2xl"
            onClick={handleOpen}
          >
            APPLY WITHout SINGPASS
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default SectionHeader;
