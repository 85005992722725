import React, { useState } from "react";
import { useSelector } from "react-redux";
import SectionStep from "./ComponentsPage/SectionStep";
import CurrencyFormatter from "lib/CurrencyFormatter";
import { Typography, Button } from "@material-tailwind/react";
import CardDetailsApplication from "./ComponentsPage/CardDetailsApplication";
import ModalConfirmation from "./ComponentsPage/ModalConfirmation";
import FullLayoutHome from "layout/FullLayoutHome";
import moment from "moment";

function DetailsApplication() {
  const [step, setStep] = useState(100);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const formApplication = useSelector((state) => state.FormApplication);
  const masterData = useSelector((state) => state.MasterData.data);
  const data = formApplication;

  const handleOpen = () => {
    setShowModalConfirmation(!showModalConfirmation);
  };

  const findNameData = (paramsTitle, paramsId) => {
    const item = masterData[paramsTitle].find((item) => item.id === paramsId);
    return item ? item.name : "";
  };

  return (
    <FullLayoutHome>
      <div className="mx-auto max-w-7xl px-4 @lg:px-6 @5xl:px-8 -mt-2 rounded-t-3xl bg-gray-bg">
        <div className="mx-auto py-8 flex flex-col h-full max-w-2xl">
          <SectionStep step={step} setStep={setStep} bg="bg-gray-bg" />
          <Typography className="mt-12 mb-6 text-center" variant="h3">
            Details Confirmation
          </Typography>
          <div className="flex flex-col gap-4">
            <CardDetailsApplication
              title="Loans Details"
              step="step1"
              section={0}
              data={[
                {
                  title: "Loans Amount",
                  value: `${
                    data.loan_amount
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.loan_amount
                        )
                      : "$0"
                  }`,
                },
                {
                  title: "Tenure (Months)",
                  value: `${
                    data.tenor_id ? findNameData("tenor", data.tenor_id) : "0"
                  }`,
                },
                {
                  title: "Main Purpose",
                  value: `${
                    data.main_purpose_id
                      ? findNameData("purposes", data.main_purpose_id)
                      : "0"
                  }`,
                },
              ]}
            />
            <CardDetailsApplication
              title="General Information"
              step="step1"
              section={0}
              data={[
                {
                  title: "Full Name",
                  value: `${data.full_name ? data.full_name : "-"}`,
                },
                {
                  title: "Date of Birth",
                  value: `${
                    data.date_of_birth
                      ? moment(data.date_of_birth).format("DD/MM/YYYY")
                      : "-"
                  }`,
                },
                {
                  title: "Residency",
                  value: `${
                    data.residency_id
                      ? findNameData("residencies", data.residency_id)
                      : "-"
                  }`,
                },
                {
                  title: "Nationaly",
                  value: `${
                    data.nationality_id
                      ? findNameData("nationalities", data.nationality_id)
                      : "-"
                  }`,
                },
              ]}
            />
            <CardDetailsApplication
              title="Contact Details"
              step="step1"
              section={0}
              data={[
                {
                  title: "Email",
                  value: `${data.email ? data.email : "-"}`,
                },
                {
                  title: "Phone Number",
                  value: `${data.phone_number ? data.phone_number : "-"}`,
                },
              ]}
            />
            <CardDetailsApplication
              title="Income Details"
              step="step1"
              section={0}
              data={[
                {
                  title: "Employment Status",
                  value: `${
                    data.employment_status_id
                      ? findNameData(
                          "employment-statuses",
                          data.employment_status_id
                        )
                      : "-"
                  }`,
                },
                {
                  title: "Monthly Income",
                  value: `${
                    data.monthly_income
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.monthly_income
                        )
                      : "$0"
                  }`,
                },
              ]}
            />
            <CardDetailsApplication
              title="Personal Details"
              step="step2"
              section={50}
              data={[
                {
                  title: "NRIC/FIN Number",
                  value: `${data.nric_fin ? data.nric_fin : "-"}`,
                },
                {
                  title: "Civil Status",
                  value: `${
                    data.civil_status_id
                      ? findNameData("civil-statuses", data.civil_status_id)
                      : "-"
                  }`,
                },
                {
                  title: "Gender",
                  value: `${data.gender ? data.gender : "-"}`,
                },
              ]}
            />
            <CardDetailsApplication
              title="Employment Details"
              step="step2"
              section={50}
              data={[
                {
                  title: "Job Title",
                  value: `${
                    data.job_title_id
                      ? findNameData("job-titles", data.job_title_id)
                      : "-"
                  }`,
                },
                {
                  title: "Job Industry",
                  value: `${
                    data.job_industry_id
                      ? findNameData("job-industries", data.job_industry_id)
                      : "-"
                  }`,
                },
                {
                  title: "Length with Current Employment",
                  value: `${
                    data.length_with_current_employer_id
                      ? findNameData(
                          "length-with-current-employer",
                          data.length_with_current_employer_id
                        )
                      : "-"
                  }`,
                },
                {
                  title: "Length with Previous Employment",
                  value: `${
                    data.length_with_previous_employer_id
                      ? findNameData(
                          "length-with-previous-employer",
                          data.length_with_previous_employer_id
                        )
                      : "-"
                  }`,
                },
              ]}
            />
            <CardDetailsApplication
              title="Residential Details"
              step="step2"
              section={50}
              data={[
                {
                  title: "Address",
                  value: `${data.address ? data.address : "-"}`,
                },
                {
                  title: "Unit",
                  value: `${data.unit ? data.unit : "-"}`,
                },
                {
                  title: "Zip Code",
                  value: `${data.zip_code ? data.zip_code : "-"}`,
                },
                {
                  title: "Type Housing",
                  value: `${
                    data.housing_type_id
                      ? findNameData("housing-types", data.housing_type_id)
                      : "-"
                  }`,
                },
                {
                  title: "Housing Status",
                  value: `${
                    data.housing_status_id
                      ? findNameData("housing-statuses", data.housing_status_id)
                      : "-"
                  }`,
                },
                {
                  title: "Length of Stay (years)",
                  value: `${
                    data.length_of_stay_year_id
                      ? findNameData(
                          "length-of-stays",
                          data.length_of_stay_year_id
                        )
                      : "-"
                  }`,
                },
                {
                  title: "Do You Own a Proprety",
                  value: `${data.is_own_property ? "Yes" : "No"}`,
                },
              ]}
            />
            <CardDetailsApplication
              title="Your Financial Commitments"
              step="step3"
              section={100}
              data={[
                {
                  title: "Do You Have Any Existing Loans?",
                  value: `${data.is_have_existing_loan ? "Yes" : "No"}`,
                },
                {
                  title: "Existing Loan Amount(Moneylenders) ",
                  value: `${
                    data.existing_loan_amount_moneylender
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.existing_loan_amount_moneylender
                        )
                      : "$0"
                  }`,
                },
                {
                  title: "Existing Loan Amount(Banks/CC) ",
                  value: `${
                    data.existing_loan_amount_bank
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.existing_loan_amount_bank
                        )
                      : "$0"
                  }`,
                },
                {
                  title: "Total Existing Loan Amount ",
                  value: `${
                    data.total_existing_loan_amount
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.total_existing_loan_amount
                        )
                      : "$0"
                  }`,
                },
              ]}
            />
            <CardDetailsApplication
              title="Your Current Monthly Repayment"
              step="step3"
              section={100}
              data={[
                {
                  title: "Monthly Repayment(Banks/CC) ",
                  value: `${
                    data.monthly_repayment_bank
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.monthly_repayment_bank
                        )
                      : "$0"
                  }`,
                },
                {
                  title: "Monthly Repayment(Moneylenders) ",
                  value: `${
                    data.monthly_repayment_moneylender
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.monthly_repayment_moneylender
                        )
                      : "$0"
                  }`,
                },
                {
                  title: "Total Monthly Loan Repayment ",
                  value: `${
                    data.total_monthly_loan_repayment
                      ? CurrencyFormatter("en-SG", "SGD", 0).format(
                          data.total_monthly_loan_repayment
                        )
                      : "$0"
                  }`,
                },
                {
                  title:
                    "Are you talking to an agency or intermediary regarding bankruptcy or debt restructuring service?",
                  value: `${data.is_agency_related ? "Yes" : "No"}`,
                },
              ]}
            />
          </div>
          <div className="mt-8 flex flex-row justify-center w-full">
            <Button
              onClick={() => setShowModalConfirmation(true)}
              size="lg"
              className="bg-primary w-full text-white flex justify-center items-center py-4 font-poppins font-semibold rounded-xl tracking-wide"
            >
              SEND APPLICATION
            </Button>
          </div>
          <ModalConfirmation
            showModalConfirmation={showModalConfirmation}
            handleOpen={handleOpen}
            data={data}
          />
        </div>
      </div>
    </FullLayoutHome>
  );
}

export default DetailsApplication;
