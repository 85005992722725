import React from "react";
import Calculator from "./ComponentsPage/Calculator";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import {
  DataHowWeWork,
  DataIconUser,
  DataPatners,
} from "./ComponentsPage/DefaultData";
import ListFaq from "./ComponentsPage/ListFaq";
import Carousel from "react-multi-carousel";
import FullLayoutHome from "layout/FullLayoutHome";

function Home() {
  return (
    <FullLayoutHome bgHeader="bg-white">
      <div className="z-10 relative">
        <div className="mx-auto relative isolate overflow-hidden pb-12 bg-white">
          <div className="mx-auto max-w-7xl container">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-2 xl:gap-x-8">
              <div className="h-auto flex flex-col justify-center px-6 lg:px-8">
                <h1 className="max-w-2xl font-bold tracking-tight text-black-primary text-4xl lg:text-5xl">
                  The best loan tailored <br /> just for you.
                </h1>
                <div className="mt-6 max-w-xl">
                  <p className="text-base font-normal text-black-primary">
                    Loanly is a leading cash loan application platform dedicated
                    to providing convenient, accessible, and transparent
                    financial solutions to individuals in need of short-term
                    funds. Our platform is designed with the customer in mind,
                    offering a seamless and hassle-free borrowing experience.
                  </p>
                  <div className="mt-6 flex items-center gap-x-6">
                    <Link to="/application">
                      <Button
                        size="lg"
                        className="bg-primary text-white flex items-center py-2.5 px-5 font-poppins font-medium rounded-xl text-sm tracking-wide shadow-none"
                      >
                        Apply Now
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex justify-center h-auto">
                <img
                  src="/assets/hero-1.png"
                  alt=""
                  className="w-full max-w-2xl object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(246,247,251,1) 99%)",
          }}
        >
          <div className="mx-auto container max-w-7xl py-12 px-6 lg:px-8">
            <div className="flex flex-col lg:flex-row justify-between gap-6">
              <div className="flex flex-col justify-center gap-4 flex-1">
                <h1 className="lg:text-left max-w-2xl text-4xl font-bold text-black-primary lg:col-span-2 xl:col-auto">
                  Data-Driven <br /> Decision Making
                </h1>
                <div className="h-auto mx-auto mt-6">
                  <img
                    className="h-[200px] lg:h-[350px] w-auto"
                    src="/assets/image-calculator.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="bg-white p-6 rounded-2xl shadow-lg flex-1 max-w-[550px]">
                <h5 className="text-2xl font-normal">
                  Your Personal Loan Calculator
                </h5>
                <span className="text-base text-dark-gray">
                  Helping you to make informed financial decisions on the spot.
                </span>
                <Calculator />
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="mx-auto px-6 max-w-2xl lg:max-w-xl text-center flex flex-col gap-4">
            <h2 className="font-bold text-3xl lg:text-4xl text-black-primary tracking-tight">
              Why Trust Us?
            </h2>
            <span className="text-lg font-normal mt-3 block">
              We only work with{" "}
              <a href="/about-use" className="text-primary font-bold">
                licensed
              </a>{" "}
              partners
            </span>
            <span className="text-base">
              All the financial institutions we work with are licensed by
              Monetary Authority of Singapore (MAS) and Ministry of Law.
            </span>
          </div>
          <div className="my-6">
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={1}
              centerMode={false}
              containerClass="container-with-dots"
              customTransition="all 6s linear"
              focusOnSelect={false}
              infinite
              draggable={false}
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              rewind={false}
              rewindWithAnimation={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 6,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              rtl={false}
              showDots={false}
              slidesToSlide={1}
              transitionDuration={1000}
            >
              {DataPatners.map((item, idx) => (
                <div
                  key={idx}
                  className="flex justify-center items-center bg-white rounded-lg shadow-lg py-8 px-6 my-4 mx-3"
                >
                  <img className="h-[40px] w-auto" src={item.url} alt="" />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="mt-12 py-12 px-6 lg:px-8 z-10 relative">
          <div className="mx-auto container max-w-7xl">
            <div className="mx-auto max-w-2xl lg:max-w-md text-center flex flex-col gap-4">
              <div className="flex flex-row gap-4 items-center justify-center">
                <h2 className="text-3xl lg:text-4xl font-bold text-black-primary tracking-tight">
                  We Use
                </h2>
                <img
                  className="h-8 w-auto mt-3"
                  src="/assets/singpass.png"
                  alt=""
                />
              </div>
              <span>
                The Singapore Government has authorised Loanly to use SingPass
                after strict and thorough checks. Using SingPass with Loanly is
                quick, easy and secure!
              </span>
            </div>
            <div className="mt-16 relative bg-primary-sec flex flex-col lg:flex-row rounded-3xl text-white max-w-6xl mx-auto">
              <div className="px-6 pt-6 pb-[250px] lg:p-12 basis-12/12 lg:basis-5/12 relative overflow-hidden">
                <div className="absolute top-0 left-0 right-0 rounded-tr-3xl z-0">
                  <img
                    className="block lg:hidden h-auto w-full rounded-t-3xl"
                    src="/assets/mobile-1.png"
                    alt=""
                  />
                </div>
                <h2 className="max-w-2xl text-4xl font-medium tracking-tight relative z-10">
                  How We Work
                </h2>
                <div className="flex flex-col gap-3 mt-8 relative z-10">
                  {DataHowWeWork.map((item, idx) => {
                    return (
                      <div
                        className="flex flex-row items-start gap-4 lg:gap-6"
                        key={idx}
                      >
                        <div className="rounded-md text-xl">{item.icon}</div>
                        <div className="flex flex-col gap-3">
                          <span className="text-lg font-bold mt-0 lg:mt-3">
                            {item.title}
                          </span>
                          <p className="text-base font-extralight ">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="absolute bottom-0 right-0 rounded-tr-3xl z-0">
                  <img
                    className="block lg:hidden h-[250px] w-[260px]"
                    src="/assets/how-we-work.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="basis-12/12 lg:basis-7/12 relative">
                <div className="absolute top-[0px] right-[0px] overflow-hidden rounded-tr-3xl">
                  <img
                    className="hidden lg:block h-[350px] w-auto"
                    src="/assets/elipse-top-right.png"
                    alt=""
                  />
                </div>
                <div className="relative w-full h-full">
                  <div className="absolute right-[-1px] bottom-[0px] ">
                    <img
                      className="hidden lg:block h-[500px] w-auto rounded-br-3xl"
                      src="/assets/how-we-work.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="absolute bottom-[-50px] right-0 lg:right-[20px] w-full lg:max-w-lg">
                  <div className="flex flex-row justify-around items-center gap-2 lg:gap-1">
                    {DataIconUser.map((item, idx) => (
                      <div
                        className="flex flex-col justify-center items-center max-w-[110px] lg:max-w-[150px] w-full relative bg-white rounded-2xl px-4 pb-2 shadow-lg"
                        key={idx}
                      >
                        <div className="p-4 rounded-full bg-[#2994FE] absolute top-[-30px]">
                          {item.icon}
                        </div>
                        <span className="text-xl lg:text-2xl font-semibold mt-8 lg:mt-10 text-black">
                          {item.title}
                        </span>
                        <p className="text-sm lg:text-base font-normal text-[#2994FE]">
                          {item.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[100px] lg:mt-28">
            <ListFaq />
          </div>
        </div>
      </div>
    </FullLayoutHome>
  );
}

export default Home;
