import React, { useState } from "react";
import { IconPlus, IconMinus } from "@tabler/icons-react";

const CardFaq = ({ item }) => {
  const [openDesc, setOpenDesc] = useState(false);

  return (
    <div
      className={`flex flex-row justify-between bg-white px-6 py-6 lg:px-[72px] lg:py-[30px] rounded-2xl shadow-lg ${
        openDesc ? "" : "items-center"
      }`}
    >
      <div className="flex flex-col gap-3 basis-4/6">
        <span className="font-semibold text-base">
          {item ? item.question : "-"}
        </span>
        {openDesc && (
          <span className="text-base">{item ? item.answer : "-"}</span>
        )}
      </div>
      <div className="basis-1/6 flex justify-end items-start">
        <button
          className="text-white p-2 lg:p-4 rounded-full bg-[#2994FE] cursor-pointer"
          onClick={() => setOpenDesc(!openDesc)}
        >
          {openDesc ? (
            <IconMinus className="w-5 h-5" />
          ) : (
            <IconPlus className="w-5 h-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default CardFaq;
