import { FooterNavigation } from "./DataMenu";

export default function FooterPage() {
  return (
    <footer>
      <div className="bg-primary-sec relative overflow-hidden">
        <div className="absolute right-0 top-0 z-0">
          <img
            className="block lg:hidden h-[240px] w-auto"
            src="/assets/elipse-top-right.png"
            alt=""
          />
          <img
            className="hidden lg:block h-[280px] w-auto"
            src="/assets/elipse-footer-top-right.png"
            alt=""
          />
        </div>
        <div className="absolute left-0 bottom-0 z-0">
          <img
            className="h-[100px] lg:h-[200px] w-auto"
            src="/assets/elipse-footer-bottom-left.png"
            alt=""
          />
        </div>
        <div className="mx-auto container overflow-hidden px-6 py-8 lg:py-20 lg:px-8 relative z-10">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center ">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-10 lg:h-12 w-auto"
                src="/assets/loanly_white.webp"
                alt=""
              />
            </a>
            <nav
              className="mt-6 lg:mt-0 -mb-0 lg:-mb-6 flex flex-col lg:flex-row lg:justify-center lg:space-x-12"
              aria-label="Footer"
            >
              {FooterNavigation.main.map((item) => (
                <div key={item.name} className="pb-6">
                  <a
                    href={item.href}
                    className="text-base font-medium leading-6 text-white hover:text-gray-300"
                  >
                    {item.name}
                  </a>
                </div>
              ))}
            </nav>
            <div className="flex flex-row lg:justify-center space-x-10">
              {FooterNavigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-white hover:text-gray-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#0E1C33] py-4">
        <p className="text-center text-sm leading-5 text-white">
          Copyright &copy; Loan Broker 2023.
        </p>
      </div>
    </footer>
  );
}
