import React from "react";
import { Input } from "@material-tailwind/react";
import { useField } from "formik";

const InputComponentCostume = ({
  label,
  setFieldValue,
  placeholder,
  size,
  type,
  ...props
}) => {
  const [field, meta] = useField(props);

  const onHandleChange = (value) => {
    setFieldValue(field.name, value);
  };

  return (
    <div>
      <Input
        label={label}
        className="px-3.5 bg-white font-semibold !text-black"
        color="blue"
        size={size}
        placeholder={placeholder}
        onChange={(e) => {
          onHandleChange(e.target.value);
        }}
        error={Boolean(meta.touched && meta.error)}
        value={field.value || ""}
        type={type ? type : "text"}
        {...props}
      />
      {meta.touched && meta.error && (
        <div className="mt-1 text-sm text-red-500">{meta.error}</div>
      )}
    </div>
  );
};

export default InputComponentCostume;
