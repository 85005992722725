import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";

const CardSmallBlog = () => {
  return (
    <Card className="w-full flex-row">
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 w-[30%] shrink-0 rounded-r-none"
      >
        <img
          src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
          alt="card"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody className="px-2.5 py-3 flex flex-col gap-3 justify-center">
        <Typography
          variant="h6"
          color="blue-gray"
          className="text-sm font-bold"
        >
          Lyft launching cross-platform service this week
        </Typography>
        <div className="flex flex-row gap-2 items-center">
          <Avatar
            size="sm"
            variant="circular"
            alt="natali craig"
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
            className="border-2 border-white hover:z-10 h-[30px] w-[30px]"
          />
          <span className="text-sm font-normal">{"Jhon Doe"}</span>
        </div>
      </CardBody>
    </Card>
  );
};

export default CardSmallBlog;
