import FullLayoutHome from "layout/FullLayoutHome";
import CardBlog from "./ComponentsPage/CardBlog";
import { IconSearch } from "@tabler/icons-react";
import Paggination from "components/Paggination";
import { DummyData } from "./ComponentsPage/DefaultData";

const Blog = () => {
  return (
    <FullLayoutHome>
      <div className="px-6 lg:px-8 z-10 relative">
        <div className="my-16 container mx-auto">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl sm:text-4xl font-semibold text-slate-900 tracking-tight dark:text-slate-200">
              Blog Articles
            </h2>
            <p className="text-lg text-black max-w-[500px] mx-auto mt-6">
              From personal articles to loan guide, here are some helpfull 101
              tips for both beginners and experts.
            </p>
            <div className="mt-8">
              <div className="relative mt-2 rounded-2xl shadow-sm">
                <input
                  type="text"
                  name="account-number"
                  id="account-number"
                  className="block w-full rounded-2xl border-0 py-2.5 pl-4 pr-10 text-gray-900 placeholder:text-gray-400 focus:!ring-2 focus:!ring-inset focus:!ring-primary sm:text-sm sm:leading-6 !outline-none"
                  placeholder="I am looking for"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <IconSearch
                    className="h-6 w-6 text-black"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-1 py-12 lg:px-8 mt-2">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
              {DummyData.map((item) => {
                return <CardBlog key={item.id} item={item} />;
              })}
            </div>
            <div className="flex flex-row justify-center text-center mt-10">
              <Paggination />
            </div>
          </div>
        </div>
      </div>
    </FullLayoutHome>
  );
};

export default Blog;
