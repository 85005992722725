import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Button, Input } from "@material-tailwind/react";
import Slider from "react-input-slider";

const Calculator = () => {
  const [loanNumber, setLoanNumber] = useState(30000);
  const [tenure, setTenure] = useState(6);

  const InputPriceProp = {
    label: "Loans Amount",
    color: "blue",
    size: "lg",
    className:
      "px-2 lg:px-4 !border !border-[#97D0FF] !bg-[#DEF3FC] !text-primary !text-base font-semibold ring-1 ring-transparent placeholder:text-[#4F7DB1] focus:!border-primary focus:!border-t-primary focus:ring-primary/10 w-full lg:min-w-[200px] !min-w-[100%]",
    labelProps: {
      className: "hidden",
    },
    containerProps: { className: "!min-w-[60px] lg:min-w-[200px]" },
  };

  const handleLoansNumber = (value) => {
    setLoanNumber(value);
  };

  const handleTenure = (value) => {
    setTenure(value);
  };

  const handleCalculation = (principal, tenureMonths) => {
    const monthlyInterestRate = 0.04 / 12 / 100;
    const tenure = tenureMonths;

    const numerator =
      principal *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, tenure);
    const denominator = Math.pow(1 + monthlyInterestRate, tenure) - 1;
    const monthlyPayment = numerator / denominator;

    const formatter = new Intl.NumberFormat("en-SG", {
      style: "currency",
      currency: "SGD",
      minimumFractionDigits: 0,
    });
    const formatValue = formatter.format(Math.round(monthlyPayment));
    const resultFormatValue = formatValue.replace("$", " ");
    return resultFormatValue;
  };

  return (
    <div className="flex flex-col gap-4 my-4">
      <div className="flex flex-col">
        <span className="text-base font-semibold text-black-primary">
          I Want To Borrow
        </span>
        <div className="flex flex-row justify-between items-center gap-4 mt-3">
          <div className="flex flex-col relative basis-8/12 gap-3">
            <Slider
              axis="x"
              xstep={1}
              xmin={8000}
              xmax={200000}
              x={loanNumber}
              onChange={({ x }) => handleLoansNumber(x)}
              styles={{
                track: {
                  backgroundColor: "#eeeeee",
                  height: 6,
                  width: "100%",
                },
                active: {
                  backgroundColor: "#2997F9",
                },
                thumb: {
                  width: 20,
                  height: 20,
                  backgroundColor: "#2997F9",
                },
              }}
            />
            <div className="flex flex-row justify-between items-center">
              <span className="text-primary font-normal text-base">
                $ 8.000
              </span>
              <span className="text-primary font-normal text-base">
                $ 200.000
              </span>
            </div>
          </div>
          <div className="basis-4/12">
            <NumericFormat
              value={loanNumber}
              onValueChange={(values) => handleLoansNumber(values.value)}
              customInput={Input}
              prefix={"$"}
              thousandSeparator=","
              {...InputPriceProp}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-semibold text-black">Tenure</span>
        <div className="flex flex-row justify-between items-center gap-4 mt-3">
          <div className="flex flex-col relative basis-8/12 gap-3">
            <Slider
              axis="x"
              xstep={1}
              xmin={3}
              xmax={72}
              x={tenure}
              onChange={({ x }) => handleTenure(x)}
              styles={{
                track: {
                  backgroundColor: "#eeeeee",
                  height: 6,
                  width: "100%",
                },
                active: {
                  backgroundColor: "#2997F9",
                },
                thumb: {
                  width: 20,
                  height: 20,
                  backgroundColor: "#2997F9",
                },
              }}
            />
            <div className="flex flex-row justify-between items-center">
              <span className="text-primary font-normal text-base">
                6 Months
              </span>
              <span className="text-primary font-normal text-base">
                72 Months
              </span>
            </div>
          </div>
          <div className="basis-4/12">
            <Input
              color="blue"
              size="lg"
              className="px-2 lg:px-4 !border !border-[#97D0FF] !bg-[#DEF3FC] !text-primary !text-base font-semibold ring-1 ring-transparent placeholder:text-[#4F7DB1] focus:!border-primary focus:!border-t-primary focus:ring-primary/10 w-full !min-w-[100%]"
              label="Months"
              containerProps={{ className: "!min-w-[100px] lg:min-w-[200px]" }}
              labelProps={{
                className: "hidden",
              }}
              value={tenure}
              onChange={(e) => setTenure(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-4">
        <span className="text-base font-semibold text-black-primary">
          Your Monthly Payment
        </span>
        <span className="text-center flex items-center">
          ${""}
          <span className="text-gradient text-4xl font-semibold">
            {loanNumber !== 0 ? handleCalculation(loanNumber, tenure) : 0}
          </span>
        </span>
      </div>
      <div className="mt-2">
        <Link to="/application">
          <Button
            size="lg"
            className="bg-primary text-white flex items-center justify-center py-3 px-7 font-poppins font-medium rounded-xl text-sm tracking-wide shadow-lg w-full"
          >
            APPLY NOW
          </Button>
        </Link>
        <span className="text-xs text-dark-gray mt-4 block">
          * For a loan amount of $20,000, APR of 6.95%, 5 year tenure, the tot.
          loan payable will be $26,940 with a monthly cost of $449. An
          individualised APR will be presented at the end of your application.
          Max. APR 8.88%. Min. repayment period 3 months, max. 72 months.
        </span>
      </div>
    </div>
  );
};

export default Calculator;
