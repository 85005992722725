import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Avatar } from "@material-tailwind/react";
import FullLayoutHome from "layout/FullLayoutHome";
import { DummyData } from "./ComponentsPage/DefaultData";
import CardSmallBlog from "./ComponentsPage/CardSmallBlog";

const BlogDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (id) {
      const result = DummyData.find((item) => item.id === id);
      setData(result);
    }
  }, [id]);

  return (
    <FullLayoutHome>
      <div className="px-6 lg:px-8 z-10 relative mx-auto max-w-7xl">
        <div className="mt-4 mb-10 lg:my-16 grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-8">
            <div className="flex flex-col gap-8">
              <h2 className="sm:text-6xl lg:text-5xl font-semibold text-slate-900 tracking-wide !leading-tight">
                {data?.title}
              </h2>
              <div className="flex flex-row items-center gap-3">
                <Avatar
                  size="sm"
                  variant="circular"
                  alt="natali craig"
                  src={data?.user.image}
                  className="border-2 border-white hover:z-10"
                />
                <span className="font-light">
                  by <span className="font-bold">{data?.user.name}</span>, last
                  update <span className="font-bold">January 1st, 2024</span>
                </span>
              </div>
              <img
                src={data?.image}
                alt="ui/ux review check"
                className="rounded-2xl"
              />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. <br />
                <br /> It has survived not only five centuries, but also the
                leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum.
                <br />
                <br />
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="flex flex-col px-3 gap-3">
              {[...Array(4)].map((item, idx) => {
                return <CardSmallBlog key={idx} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </FullLayoutHome>
  );
};

export default BlogDetails;
