import React, { useState } from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";

const Paggination = () => {
  const [active, setActive] = useState(1);

  const getItemProps = (index) => ({
    className:
      active === index
        ? "bg-primary text-white"
        : "shadow-none bg-transparent text-gray-500 hover:shadow-none hover:text-primary",
    color: "gray",
    onClick: () => setActive(index),
  });

  const next = () => {
    if (active === 5) return;

    setActive(active + 1);
  };

  const prev = () => {
    if (active === 1) return;

    setActive(active - 1);
  };

  return (
    <div className="flex items-center gap-4">
      <Button
        variant="text"
        className={`flex items-center gap-2 ${
          active === 1 ? "text-gray-500" : "text-primary"
        } `}
        onClick={prev}
        disabled={active === 1}
      >
        <IconArrowLeft strokeWidth={2} className="h-4 w-4" />
      </Button>
      <div className="flex items-center gap-2">
        <IconButton {...getItemProps(1)}>1</IconButton>
        <IconButton {...getItemProps(2)}>2</IconButton>
        <IconButton {...getItemProps(3)}>3</IconButton>
        <IconButton {...getItemProps(4)}>4</IconButton>
        <IconButton {...getItemProps(5)}>5</IconButton>
      </div>
      <Button
        variant="text"
        className={`flex items-center gap-2 ${
          active === 5 ? "text-gray-500" : "text-primary"
        } `}
        onClick={next}
        disabled={active === 5}
      >
        <IconArrowRight strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default Paggination;
