import React, { useState } from "react";
import { IconCheck, IconSend, IconAlertTriangle } from "@tabler/icons-react";
import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  Spinner,
  Alert,
} from "@material-tailwind/react";
import { API_URL } from "lib/network/api";
import { useDispatch } from "react-redux";
import { resetFormDataApplication } from "store/action/formApplicationActions";
import axios from "axios";

const ModalConfirmation = ({ showModalConfirmation, handleOpen, data }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationText, setConfirmationText] = useState({
    title: "Confirm Your Data",
    subtitle:
      "This data will be sent to our team, make sure all the data is correct",
  });
  const [successSent, setSuccessSent] = useState(false);
  const [error, setError] = useState({
    errorShow: false,
    title: "",
  });

  const URL_BROWSER = process.env.REACT_APP_URL_BROWSER;

  const onConfirm = async () => {
    setIsLoading(true);
    axios
      .post(`${API_URL}/loan/register-and-apply`, data)
      .then((res) => {
        setTimeout(() => {
          setSuccessSent(true);
          dispatch(resetFormDataApplication());
          setIsLoading(false);
          setConfirmationText({
            title: "Apply Success",
            subtitle:
              "Your application has been accepted. To view your application, please log in to your account using the password we have sent via email.",
          });
        }, 500);
      })
      .catch((error) => {
        setIsLoading(false);
        setError({
          errorShow: true,
          title: error.detail.error,
        });
      });
  };

  const goToDashboard = () => {
    window.location.href = `${URL_BROWSER}/login`;
  };

  return (
    <Dialog
      open={showModalConfirmation}
      handler={handleOpen}
      size="sm"
      className="p-2 lg:p-7 rounded-2xl select-none"
      dismiss={{
        outsidePress: false,
      }}
    >
      <DialogHeader>
        <div className="relative w-full items-center flex flex-col justify-center">
          {successSent ? (
            <IconCheck className="w-16 h-16 lg:w-30 lg:h-30 text-primary" />
          ) : (
            <IconSend className="w-16 h-16 lg:w-30 lg:h-30 text-primary" />
          )}
          <Typography className="mt-6 text-[30px] text-center font-semibold mx-auto text-primary">
            {confirmationText.title}
          </Typography>
          <Typography
            variant="small"
            className="mt-4 text-center font-normal mx-auto w-[230px] lg:w-[360px] text-[15px]"
          >
            {confirmationText.subtitle}
          </Typography>
        </div>
      </DialogHeader>
      <DialogFooter>
        {isLoading ? (
          <div className="flex flex-col justify-center mt-5 gap-4 w-full">
            <Spinner className="h-10 w-10 mx-auto" color="blue" />
          </div>
        ) : (
          <>
            {successSent ? (
              <div className="flex flex-col justify-center w-full gap-4">
                <Button
                  className="bg-primary font-normal w-full py-3 lg:py-3.5 tracking-wider text-sm rounded-2xl shadow-gray-900/50"
                  onClick={goToDashboard}
                >
                  LOGIN
                </Button>
              </div>
            ) : (
              <div className="flex flex-col gap-4 w-full">
                <Button
                  className="bg-primary font-normal w-full py-3 lg:py-3.5 tracking-wider text-sm rounded-2xl shadow-gray-900/50"
                  onClick={onConfirm}
                >
                  CONFIRM
                </Button>
                <Button
                  className="text-primary border-2 border-primary bg-white font-medium w-full py-3 lg:py-3.5 tracking-wider text-sm rounded-2xl"
                  onClick={handleOpen}
                >
                  NO, GO BACK
                </Button>
              </div>
            )}
          </>
        )}
        <Alert
          className="mt-3 py-2 px-3 text-sm lg:text-base lg:p-6 flex flex-row items-center"
          variant="gradient"
          open={error.errorShow}
          icon={<IconAlertTriangle />}
          color="red"
          action={
            <Button
              variant="text"
              color="white"
              size="sm"
              className="ml-auto"
              onClick={() =>
                setError({
                  errorShow: false,
                  title: "",
                })
              }
            >
              Close
            </Button>
          }
        >
          {error.title}
        </Alert>
      </DialogFooter>
    </Dialog>
  );
};

export default ModalConfirmation;
