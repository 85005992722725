import { Disclosure } from "@headlessui/react";
import { IconChevronRight } from "@tabler/icons-react";

const AccordionPage = ({ title, description, index }) => {
  return (
    <div
      className={`pt-1.5 pb-3 ${
        index === 2 ? "" : "border-b-2 border-neutral-200"
      }`}
    >
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full flex justify-between">
              <span className="text-sm font-semibold">{title}</span>
              <IconChevronRight
                className={`${
                  open ? "rotate-90 transform" : ""
                } h-6 w-6 text-primary`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-2 text-sm font-normal">
              {description}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default AccordionPage;
