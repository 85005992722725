import React from "react";
import FullLayoutHome from "layout/FullLayoutHome";
import ListFaq from "./ComponentsPage/ListFaq";

const Faq = () => {
  return (
    <FullLayoutHome>
      <div className="relative">
        <ListFaq />
      </div>
    </FullLayoutHome>
  );
};

export default Faq;
