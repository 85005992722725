import SectionCardIcon from "./ComponentsPage/SectionCardIcon";
import AccordionPage from "./ComponentsPage/AccordionPage";
import { dataSectionCard, dataSectionQuestion } from "./DefaultData";

const SectionInformation = () => {
  return (
    <div className="col-span-12 lg:col-span-1 flex flex-col space-y-8">
      <div className="flex flex-col gap-4 bg-white shadow-lg p-6 rounded-xl">
        {dataSectionCard.map((item, idx) => {
          return (
            <div key={idx}>
              <SectionCardIcon
                title={item.title}
                description={item.description}
                icon={item.icon}
              />
            </div>
          );
        })}
      </div>
      <div className="bg-white shadow-lg p-6 rounded-xl">
        <div className="pb-3 mb-3 border-b-[3px] border-neutral-200 ">
          <span className="text-base font-semibold">Requirements to Apply</span>
        </div>
        <div>
          <ul className="text-sm flex flex-col gap-2 font-medium">
            <li>&#x2022; 18 - 70 Years Old</li>
            <li>&#x2022; Minimum Monthly Income 0f $1200</li>
            <li>&#x2022; Salaried Singaporean/PR/EP Holder</li>
          </ul>
        </div>
      </div>
      <div className="bg-white shadow-lg p-6 rounded-xl">
        <div className="pb-3 mb-3 border-b-[3px] border-neutral-200 ">
          <span className="text-base font-semibold">Common Questions</span>
        </div>
        <div className="flex flex-col gap-2">
          {dataSectionQuestion.map((item, idx) => {
            return (
              <AccordionPage
                key={idx}
                title={item.title}
                description={item.description}
                index={idx}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionInformation;
