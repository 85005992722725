import { API_URL, get } from "lib/network/api";

export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST"
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS"
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE"

export const fetchProfileRequest = () => ({
    type: FETCH_PROFILE_REQUEST,
});

export const fetchProfileSuccess = (profile) => ({
    type: FETCH_PROFILE_SUCCESS,
    payload: profile,
});

export const fetchProfileFailure = (error) => ({
    type: FETCH_PROFILE_FAILURE,
    payload: error,
});

export const fetchProfile = () => {
    return (dispatch) => {
        dispatch(fetchProfileRequest());
        get(`${API_URL}/users`)
            .then((response) => {
                dispatch(fetchProfileSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchProfileFailure(error));
            });
    };
};