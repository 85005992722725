import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";

const ModalLogin = ({ showModal, handleOpen }) => {
  const onConfirm = async () => {
    console.log("login");
  };

  return (
    <Dialog
      open={showModal}
      handler={handleOpen}
      size="sm"
      className="p-2 lg:p-7 rounded-2xl select-none"
    >
      <DialogHeader>
        <div className="relative w-full items-center flex flex-col justify-center">
          <Typography className="mt-6 text-[30px] text-center font-semibold mx-auto text-primary">
            Your email has been registered
          </Typography>
          <Typography
            variant="small"
            className="mt-4 text-center font-normal mx-auto w-[230px] lg:w-[360px] text-[15px]"
          >
            Login to continue filling in the application form
          </Typography>
        </div>
      </DialogHeader>
      <DialogFooter>
        <div className="flex flex-col gap-4 w-full">
          <Button
            className="bg-primary font-normal w-full py-3 lg:py-3.5 tracking-wider text-sm rounded-2xl shadow-gray-900/50"
            onClick={onConfirm}
          >
            LOGIN
          </Button>
          <Button
            className="text-primary border-2 border-primary bg-white font-medium w-full py-3 lg:py-3.5 tracking-wider text-sm rounded-2xl"
            onClick={handleOpen}
          >
            I have another email
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default ModalLogin;
