import {
  IconClockCheck,
  IconCash,
  IconCertificate2,
} from "@tabler/icons-react";


const dataSectionCard = [
  {
    title: "Apply in 2 Minutes",
    description:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
    icon: <IconClockCheck size={24} color="#338EE6" />,
  },
  {
    title: "Borrow up to $200,000",
    description:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
    icon: <IconCash size={24} color="#338EE6" />,
  },
  {
    title: "No Obligations",
    description:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
    icon: <IconCertificate2 size={24} color="#338EE6" />,
  },
];

const dataSectionQuestion = [
  {
    title: "What if i want different amount?",
    description:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
  },
  {
    title: "What if i am unable to repay this loan?",
    description:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",

  },
  {
    title: "What happens once I confirm this offer?",
    description:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
  },
]

export { dataSectionCard, dataSectionQuestion };
