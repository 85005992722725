import FullLayoutHome from "layout/FullLayoutHome";
import SectionForm from "./SectionForm";
import SectionInformation from "./SectionInformation";

function Application() {
  return (
    <FullLayoutHome>
      <div className="mx-auto max-w-7xl px-4 @lg:px-6 @5xl:px-8 rounded-3xl ">
        <div className="mx-auto py-8 flex flex-col lg:grid lg:grid-cols-3 gap-10 h-full">
          <SectionForm />
          <SectionInformation />
        </div>
      </div>
    </FullLayoutHome>
  );
}

export default Application;
