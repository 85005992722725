import React, { useState, useEffect } from "react";
import { Button, Input, Select, Option } from "@material-tailwind/react";
import { API_URL } from "lib/network/api";
import axios from "axios";
import { Formik } from "formik";
import { IconChevronRight } from "@tabler/icons-react";
import { StepFirst } from "./SchemaValidation";
import InputNumberComponent from "components/InputNumberComponent";
import InputSelectComponent from "components/InputSelectComponent";
import InputComponentCostume from "components/InputComponentCostume";
import SectionButtonStep from "../ComponentsPage/SectionButtonStep";
import SectionHeader from "../ComponentsPage/SectionHeader";
import toast from "react-hot-toast";
import ModalLogin from "../ComponentsPage/ModalLogin";
import moment from "moment";

const FirstStep = ({
  step,
  setStep,
  setStepRender,
  masterData,
  data,
  setData,
}) => {
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: "Email has been register",
  });
  const [errorDate, setErrorDate] = useState({
    show: false,
    message: "Please enter valid Date",
  });
  const [datebirth, setDateBirth] = useState(null);

  useEffect(() => {
    if (data.date_of_birth) {
      const dateOfBirth = moment(data.date_of_birth);
      setDateBirth(dateOfBirth);
    }
  }, [data.date_of_birth]);

  const openModal = () => {
    setShowModalLogin(!showModalLogin);
  };

  const validateEmail = (values) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(values)) {
      return;
    }

    const payload = {
      email: values,
    };
    axios
      .post(`${API_URL}/users/email-address`, payload)
      .then((res) => {
        const { data } = res;
        if (data.data) {
          setShowModalLogin(true);
          setError({
            ...error,
            show: true,
          });
        } else {
          setShowModalLogin(false);
          setError({
            ...error,
            show: false,
          });
        }
      })
      .catch((error) => {
        toast.error(
          "Something Went Wrong, Can't validate email, try again later"
        );
      });
  };

  const onChangeEmail = (setFieldValue, name, value) => {
    setError({
      ...error,
      show: false,
    });
    setFieldValue(name, value);
  };

  const onChangeDate = (setFieldValue, name, value) => {
    setErrorDate({
      ...errorDate,
      show: false,
    });

    if ((name === "day" || name === "month") && value.length <= 2) {
      setFieldValue(name, value);
    } else if (name === "year" && value.length <= 4) {
      setFieldValue(name, value);
    }
  };

  const onChangeSelect = (setFieldValue, name, value) => {
    if (name === "residency_id") {
      setFieldValue("residency_id", value);
      if (value === "1" || value === "2") {
        setFieldValue("residency_id", value);
        setFieldValue("nationality_id", "1");
      }
    }

    setFieldValue(name, value);
  };

  const onChangePhoneNumber = (setFieldValue, name, value) => {
    if (value.length <= 8) {
      setFieldValue(name, value);
    }
  };

  const handleValidation = (day, month, year, setFieldValue, name) => {
    const resDay = day.length === 1 ? "0" + day : day;
    const resMonth = month.length === 1 ? "0" + month : month;

    if (name === "day") {
      setFieldValue(name, resDay);
    }

    if (name === "month") {
      setFieldValue(name, resMonth);
    }

    const dateInput = moment(
      `${year}-${resMonth}-${resDay}`,
      "YYYY-MM-DD",
      true
    );
    if (!dateInput.isValid()) {
      setErrorDate({
        show: true,
        message: "Please enter valid Date",
      });
    } else {
      setErrorDate({
        ...errorDate,
        show: false,
      });
    }
  };

  const handleOnNext = async (values) => {
    if (error.show) {
      return;
    }

    const dateOfBirth = moment(
      `${values.year}-${values.month}-${values.day}`,
      "YYYY-MM-DD"
    ).valueOf();

    setData({
      ...data,
      loan_amount: values.loan_amount,
      tenor_id: parseInt(values.tenor_id),
      main_purpose_id: parseInt(values.main_purpose_id),
      full_name: values.full_name,
      date_of_birth: dateOfBirth,
      residency_id: parseInt(values.residency_id),
      nationality_id: parseInt(values.nationality_id),
      email: values.email,
      phone_number: values.phone_number,
      employment_status_id: parseInt(values.employment_status_id),
      monthly_income: values.monthly_income,
    });
    setStepRender("step2");
    setStep(step + 50);
  };

  return (
    <div>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            loan_amount: data.loan_amount ? data.loan_amount : 20000,
            tenor_id: data.tenor_id ? data.tenor_id : 4,
            main_purpose_id: data.main_purpose_id,
            full_name: data.full_name,
            residency_id: data.residency_id
              ? data.residency_id.toString()
              : null,
            nationality_id: data.nationality_id
              ? data.nationality_id.toString()
              : null,
            email: data.email,
            phone_number: data.phone_number,
            employment_status_id: data.employment_status_id,
            monthly_income: data.monthly_income,
            day: datebirth ? datebirth.format("DD") : "",
            month: datebirth ? datebirth.format("MM") : "",
            year: datebirth ? datebirth.format("YYYY") : "",
          }}
          validationSchema={StepFirst}
          onSubmit={async (values) => {
            handleOnNext(values);
          }}
        >
          {({ handleSubmit, setFieldValue, values, touched, errors }) => (
            <form className="space-y-8" onSubmit={handleSubmit}>
              <div>
                <SectionHeader title={"Loan Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <InputNumberComponent
                    name="loan_amount"
                    label="Loan Amount"
                    setFieldValue={setFieldValue}
                  />
                  <InputSelectComponent
                    name="tenor_id"
                    option={masterData.data.tenor}
                    label="Tenure (Month)"
                    setFieldValue={setFieldValue}
                  />
                  <InputSelectComponent
                    name="main_purpose_id"
                    option={masterData.data.purposes}
                    label="Main Purpose"
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div>
                <SectionHeader title={"General Information"} />
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
                  <InputComponentCostume
                    name="full_name"
                    label="Full Name"
                    setFieldValue={setFieldValue}
                    placeholder="Enter your Full Name"
                    size="lg"
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <div>
                    <Select
                      label="Residency Status"
                      color="blue"
                      className="bg-white font-semibold !text-black px-3.5"
                      size="lg"
                      value={values.residency_id}
                      onChange={(val) =>
                        onChangeSelect(setFieldValue, "residency_id", val)
                      }
                      error={
                        Boolean(
                          touched["residency_id"] && errors["residency_id"]
                        ) || errorDate.show
                      }
                      menuProps={{ className: "max-h-[200px]" }}
                    >
                      {masterData.data.residencies?.length ? (
                        masterData.data.residencies.map((item, idx) => {
                          return (
                            <Option key={idx} value={`${item.id}`}>
                              {item.name}
                            </Option>
                          );
                        })
                      ) : (
                        <Option>NO DATA</Option>
                      )}
                    </Select>
                    {touched["residency_id"] && errors["residency_id"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["residency_id"]}
                      </div>
                    )}
                  </div>
                  <div>
                    <Select
                      label="Nationality"
                      color="blue"
                      className="bg-white font-semibold !text-black px-3.5"
                      size="lg"
                      value={
                        values.residency_id === "1" ||
                        values.residency_id === "2"
                          ? "1"
                          : values.nationality_id
                      }
                      onChange={(val) =>
                        onChangeSelect(setFieldValue, "nationality_id", val)
                      }
                      error={
                        Boolean(
                          touched["nationality_id"] && errors["nationality_id"]
                        ) || errorDate.show
                      }
                      menuProps={{ className: "max-h-[200px]" }}
                    >
                      {masterData.data.nationalities?.length ? (
                        masterData.data.nationalities.map((item, idx) => {
                          return (
                            <Option key={idx} value={`${item.id}`}>
                              {item.name}
                            </Option>
                          );
                        })
                      ) : (
                        <Option>NO DATA</Option>
                      )}
                    </Select>
                    {touched["nationality_id"] && errors["nationality_id"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["nationality_id"]}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <SectionHeader title={"Date of Birth"} />
                {errorDate.show && (
                  <div className="text-red-600 mt-1 text-sm">
                    {errorDate.message}
                  </div>
                )}
                <div className="grid grid-cols-3 gap-4 mt-4">
                  <div>
                    <Input
                      label="Day"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="Enter your Day"
                      value={values.day}
                      onChange={(e) =>
                        onChangeDate(setFieldValue, "day", e.target.value)
                      }
                      onBlur={() => {
                        handleValidation(
                          values.day,
                          values.month,
                          values.year,
                          setFieldValue,
                          "day"
                        );
                      }}
                      error={
                        Boolean(touched["day"] && errors["day"]) ||
                        errorDate.show
                      }
                      type="number"
                      containerProps={{
                        className: "!min-w-[100px]",
                      }}
                    />
                    {touched["day"] && errors["day"] && (
                      <>
                        <div className="text-red-600 mt-1 text-sm">
                          {errors["day"]}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Month"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="Enter your Month"
                      value={values.month}
                      onChange={(e) =>
                        onChangeDate(setFieldValue, "month", e.target.value)
                      }
                      onBlur={() => {
                        handleValidation(
                          values.day,
                          values.month,
                          values.year,
                          setFieldValue,
                          "month"
                        );
                      }}
                      error={
                        Boolean(touched["month"] && errors["month"]) ||
                        errorDate.show
                      }
                      type="number"
                      containerProps={{
                        className: "!min-w-[100px]",
                      }}
                    />
                    {touched["month"] && errors["month"] && (
                      <>
                        <div className="text-red-600 mt-1 text-sm">
                          {errors["month"]}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Year"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="Enter your Year"
                      value={values.year}
                      onChange={(e) =>
                        onChangeDate(setFieldValue, "year", e.target.value)
                      }
                      onBlur={() => {
                        handleValidation(values.day, values.month, values.year);
                      }}
                      error={
                        Boolean(touched["year"] && errors["year"]) ||
                        errorDate.show
                      }
                      type="number"
                      containerProps={{
                        className: "!min-w-[100px]",
                      }}
                    />
                    {touched["year"] && errors["year"] && (
                      <>
                        <div className="text-red-600 mt-1 text-sm">
                          {errors["year"]}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <SectionHeader title={"Contact Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <div>
                    <Input
                      label="Email"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="Enter your Email"
                      value={values.email}
                      onChange={(e) =>
                        onChangeEmail(setFieldValue, "email", e.target.value)
                      }
                      onBlur={() => {
                        validateEmail(values.email);
                      }}
                      error={
                        Boolean(touched["email"] && errors["email"]) ||
                        error.show
                      }
                      type="text"
                    />
                    {touched["email"] && errors["email"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["email"]}
                      </div>
                    )}
                    {error.show && (
                      <div className="text-red-600 mt-1 text-sm">
                        {error.message}
                      </div>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Phone Number"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="eg. 81234567"
                      value={values.phone_number}
                      onChange={(e) =>
                        onChangePhoneNumber(
                          setFieldValue,
                          "phone_number",
                          e.target.value
                        )
                      }
                      onBlur={() => {
                        validateEmail(values.phone_number);
                      }}
                      error={
                        Boolean(
                          touched["phone_number"] && errors["phone_number"]
                        ) || error.show
                      }
                      type="number"
                    />
                    {touched["phone_number"] && errors["phone_number"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["phone_number"]}
                      </div>
                    )}
                    {error.show && (
                      <div className="text-red-600 mt-1 text-sm">
                        {error.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <SectionHeader title={"Income Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <InputSelectComponent
                    name="employment_status_id"
                    option={masterData.data["employment-statuses"]}
                    label="Employment Status"
                    setFieldValue={setFieldValue}
                  />
                  <InputNumberComponent
                    name="monthly_income"
                    label="Monthly Income"
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <SectionButtonStep
                step={step}
                onGoNext={
                  <Button
                    type="submit"
                    size="md"
                    className="bg-primary text-white flex items-center pl-8 pr-6 py-2 font-poppins font-medium rounded-xl tracking-wide"
                  >
                    Next
                    <IconChevronRight />
                  </Button>
                }
              />
            </form>
          )}
        </Formik>
        <ModalLogin
          showModal={showModalLogin}
          handleOpen={openModal}
          data={data}
        />
      </div>
    </div>
  );
};

export default FirstStep;
