import ProgressBar from "@ramonak/react-progress-bar";
import { IconCheck } from "@tabler/icons-react";

const SectionStep = ({ step, bg }) => {
  return (
    <div className="relative">
      <div className="flex flex-row items-center justify-between z-[5] relative">
        <div className="flex flex-col items-center gap-2">
          <div>
            <div className="rounded-full bg-secondary flex justify-center items-center w-12 h-12 lg:w-16 lg:h-16">
              {step >= 50 ? (
                <IconCheck className="text-white h-8 w-8" />
              ) : (
                <span className="text-3xl font-light text-white">1</span>
              )}
            </div>
          </div>
          <span className="text-base font-semibold text-secondary">Apply</span>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className={`${bg ? bg : ""} px-2.5`}>
            <div
              className={`rounded-full ${
                step >= 50 ? "bg-secondary" : "bg-dark-gray"
              } flex justify-center items-center w-12 h-12 lg:w-16 lg:h-16`}
            >
              {step === 100 ? (
                <IconCheck className="text-white h-8 w-8" />
              ) : (
                <span className="text-3xl font-light text-white">2</span>
              )}
            </div>
          </div>
          <span
            className={`text-base font-semibold ${
              step >= 50 ? "text-secondary" : "text-dark-gray"
            } `}
          >
            Data
          </span>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div>
            <div
              className={`rounded-full ${
                step === 100 ? "bg-secondary" : "bg-dark-gray"
              } flex justify-center items-center w-12 h-12 lg:w-16 lg:h-16`}
            >
              <span className="text-3xl font-light text-white">3</span>
            </div>
          </div>
          <span
            className={`text-base font-semibold ${
              step === 100 ? "text-secondary" : "text-dark-gray"
            } `}
          >
            Review
          </span>
        </div>
      </div>
      <div className="absolute w-[70%] top-[30%] lg:w-[78%] z-0 left-[50%] lg:top-[35%] translate-y-[-50%] translate-x-[-50%]">
        <div className="">
          <ProgressBar
            completed={step}
            bgColor="#2894FF"
            baseBgColor="#e0e0e0"
            isLabelVisible={false}
            height="8px"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionStep;
