import React, { useState, useEffect } from "react";
import HeaderPage from "./HeaderPage";
import FooterPage from "./FooterPage";

const FullLayoutHome = ({ bgHeader, children }) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col h-screen justify-between">
      <div
        className={`fixed top-0 left-0 w-full z-[15] transition-all duration-200 ${
          isSticky ? "bg-primary" : `${bgHeader ? bgHeader : "bg-gray-bg"}`
        } `}
      >
        <HeaderPage isSticky={isSticky} />
      </div>
      <div className="pt-[80px]">{children}</div>
      <FooterPage />
    </div>
  );
};

export default FullLayoutHome;
