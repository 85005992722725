import {
    IconShieldCheck,
    IconReportSearch,
    IconCertificate,
    IconCash,
    IconUsers,
} from "@tabler/icons-react";

const DummyData = [
    {
        id: '0',
        title: 'What You Should Know About Moneylenders Rules?',
        image: 'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        user: {
            name: 'Jhon Doe',
            image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80'
        }
    },
    {
        id: '1',
        title: 'What You Should Know About Moneylenders Rules?',
        image: 'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        user: {
            name: 'Jhon Doe',
            image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80'
        }
    },
    {
        id: '2',
        title: 'What You Should Know About Moneylenders Rules?',
        image: 'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        user: {
            name: 'Jhon Doe',
            image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80'
        }
    },
    {
        id: '3',
        title: 'What You Should Know About Moneylenders Rules?',
        image: 'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        user: {
            name: 'Jhon Doe',
            image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80'
        }
    },
]

const DataHowWeWork = [
    {
        icon: <IconShieldCheck className="w-8 h-8 lg:w-16 lg:h-16" />,
        title: "Apply Securely",
        description: `Only necessary information from your application will be used by our partners to generate personalised offers for you. We do not share your contact details unit you have chosen an offer.`,
    },
    {
        icon: <IconReportSearch className="w-8 h-8 lg:w-16 lg:h-16" />,
        title: "Compare Tailored Loan Offers",
        description: `You will see the details of all loan offers personalised and unique to you. Compare and choose the loan that meets your needs! Rate are real; no markup, no ads, no hidden costs. Everything 100% transparent`,
    },
    {
        icon: <IconCash className="w-8 h-8 lg:w-16 lg:h-16" />,
        title: "Get Your Money",
        description: `After final checks and contract signing 
        at our partner’s office, you will receive your loan.`,
    },
];

const DataIconUser = [
    {
        icon: <IconUsers className="w-5 h-5 lg:w-6 lg:h-6" />,
        title: "1000+",
        description: `Users`,
    },
    {
        icon: <IconCertificate className="w-5 h-5 lg:w-6 lg:h-6" />,
        title: "2+",
        description: `Years`,
    },
    {
        icon: <IconCash className="w-5 h-5 lg:w-6 lg:h-6" />,
        title: "500+",
        description: `Loans`,
    },
];

const DataPatners = [
    {
        id: 0,
        url: '/assets/sc.png'
    },
    {
        id: 0,
        url: '/assets/dbss.png'
    },
    {
        id: 0,
        url: '/assets/posb.png'
    },
    {
        id: 0,
        url: '/assets/haesbece.png'
    },
    {
        id: 0,
        url: '/assets/sc.png'
    },
    {
        id: 0,
        url: '/assets/dbss.png'
    },
    {
        id: 0,
        url: '/assets/posb.png'
    },
    {
        id: 0,
        url: '/assets/haesbece.png'
    }
]

const DataValueWeLive = [
    {
        title: 'Prioritising Consumers',
        description: 'Every decision we make must provide value to consumers. Financial products must be cheaper, faster and better.',
        image: '/assets/about-1.png'
    },
    {
        title: 'Unbased Refferal',
        description: 'We make money from referral fees from lenders but our platform is built to maximise consumer value, not profit.',
        image: '/assets/about-2.png'
    },
    {
        title: 'Going The Extra Mile',
        description: 'We constantly think about how we can make enhance our services to consumers through our guides and tools.',
        image: '/assets/about-3.png'
    }
]

const DataFaq = [
    {
        id: 0,
        answer: 'Lorem Ipsum Lorem Ipsum',
        question: 'Lorem Ipsum Lorem Ipsum'
    },
    {
        id: 1,
        answer: 'Lorem Ipsum Lorem Ipsum',
        question: 'Lorem Ipsum Lorem Ipsum'
    }
]

export { DummyData, DataHowWeWork, DataIconUser, DataPatners, DataValueWeLive, DataFaq }