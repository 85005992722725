import React from "react";
import { useField } from "formik";

const FormRadio = ({ options, setFieldValue, ...props }) => {
  const [field, meta] = useField(props);

  const handleOptionChange = (value) => {
    setFieldValue(field.name, value);
  };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {options.map((option, index) => (
          <label
            key={index}
            className="px-3.5 py-3 bg-white font-semibold !text-black border border-blue-gray-200 rounded-lg cursor-pointer flex flex-row items-center gap-3"
          >
            <input
              type="radio"
              className="border-netral w-5 h-5 bg-white p-0 transition-all hover:before:opacity-0 checked:border-primary checked:before:bg-primary"
              name={field.name}
              value={field.value}
              checked={field.value === option.value}
              onChange={() => handleOptionChange(option.value)}
            />
            <span>{option.name}</span>
          </label>
        ))}
      </div>
      {meta.error && meta.touched && (
        <div className="error-message">{meta.error}</div>
      )}
    </div>
  );
};

export default FormRadio;
