import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-tailwind/react";
import { IconPencil } from "@tabler/icons-react";

const CardDetailsApplication = ({ title, data, step, section }) => {
  const navigate = useNavigate();

  const goToPage = (paramsPage, paramsSection) => {
    navigate(`/application?path=${paramsPage}&&section=${paramsSection}`);
  };

  return (
    <div className="bg-white rounded-3xl shadow-xl p-5 lg:p-6 mt-4 flex flex-col gap-4">
      <div className="flex flex-row pb-2 border-b-[3px] border-light-gray justify-between items-center gap-4">
        <Typography variant="h5" className="font-medium">
          {title}
        </Typography>
        <Button
          variant="outlined"
          size="sm"
          color="blue"
          className="flex flex-row items-center gap-1"
          onClick={() => goToPage(step, section)}
        >
          <IconPencil className="w-4 h-4" />
          EDIT
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-2 lg:gap-4">
        {data.map((item, idx) => {
          return (
            <div
              className={`flex flex-col gap-[2px] ${
                item.title === "Address" ? "col-span-2" : ""
              }`}
              key={idx}
            >
              <Typography variant="small" className="text-dark-gray">
                {item.title}
              </Typography>
              <Typography
                variant="h6"
                className={`${
                  item.title === "Loans Amount"
                    ? "text-gradient-secondary"
                    : "text-black"
                } font-bold text-lg lg:text-xl`}
              >
                {item.value}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardDetailsApplication;
