import axios from 'axios'
import { API_URL } from 'lib/network/api';
import { fetchStart, fetchSuccess, fetchError } from '../reducer/masterDataReducers';

export const fetchMasterData = () => async (dispatch) => {
    dispatch(fetchStart());
    try {
        const response = await axios.get(`${API_URL}/masters`);
        dispatch(fetchSuccess(response.data.data));
    } catch (error) {
        dispatch(fetchError(error.message));
    }
};
