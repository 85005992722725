import React from "react";

const SectionCardIcon = ({ title, description, icon }) => {
  return (
    <div className="flex flex-row items-start gap-4">
      <div className="bg-[#DFF3FE] p-2 rounded-md">{icon}</div>
      <div className="flex flex-col">
        <span className="text-base font-semibold">{title}</span>
        <p className="text-sm font-normal text-dark-gray">{description}</p>
      </div>
    </div>
  );
};

export default SectionCardIcon;
