import React from "react";
import { Select, Option } from "@material-tailwind/react";
import { useField } from "formik";

const InputSelectComponent = ({ option, label, setFieldValue, ...props }) => {
  const [field, meta] = useField(props);

  const onChangeSelect = (values) => {
    setFieldValue(field.name, values);
  };

  return (
    <div>
      <Select
        {...field}
        {...props}
        label={label}
        color="blue"
        className="bg-white font-semibold !text-black px-3.5"
        size="lg"
        value={field.value ? field.value.toString() : ""}
        onChange={(val) => onChangeSelect(val)}
        error={Boolean(meta.touched && meta.error)}
        menuProps={{ className: "max-h-[200px]" }}
      >
        {option?.length ? (
          option.map((item, idx) => {
            return (
              <Option key={idx} value={`${item.id}`}>
                {item.name}
              </Option>
            );
          })
        ) : (
          <Option>NO DATA</Option>
        )}
      </Select>
      {meta.touched && meta.error && (
        <div className="text-red-600 mt-1 text-sm">{meta.error}</div>
      )}
    </div>
  );
};

export default InputSelectComponent;
