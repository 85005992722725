import React from "react";
import FullLayoutHome from "layout/FullLayoutHome";
import { TermsConditionData } from "./DefaultData";

const Terms = () => {
  return (
    <FullLayoutHome>
      <div className="px-6 lg:px-8 z-10 relative mx-auto max-w-7xl">
        <div className="mt-4 mb-10 lg:my-16 grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <div className="flex flex-col gap-8">
              <h2 className="text-3xl font-semibold text-slate-900 tracking-wide !leading-tight">
                Terms
              </h2>
              <ul className="mt-2 space-y-8 text-gray-600">
                {TermsConditionData.map((item, key) => {
                  return (
                    <li className="flex gap-x-3" key={key}>
                      <span>
                        <strong className="text-base font-semibold text-gray-900">
                          {item.title}
                        </strong>{" "}
                        <br />
                        <span
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </FullLayoutHome>
  );
};

export default Terms;
