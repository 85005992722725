import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
} from "@material-tailwind/react";

const CardBlog = ({ item }) => {
  const navigate = useNavigate();

  const goToDetail = (params) => {
    navigate(`/blog/${params}`);
  };

  return (
    <Card
      className="overflow-hidden cursor-pointer"
      onClick={() => goToDetail(item.id)}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 rounded-none h-[150px]"
      >
        <img src={item.image} alt="ui/ux review check" />
      </CardHeader>
      <CardBody className="px-4 pt-3 pb-1">
        <Typography variant="h6" color="blue-gray" className="font-semibold">
          {item.title}
        </Typography>
      </CardBody>
      <CardFooter className="px-4 pt-2 pb-3 flex flex-row gap-3 items-center">
        <Avatar
          size="sm"
          variant="circular"
          alt="natali craig"
          src={item.user.image}
          className="border-2 border-white hover:z-10"
        />
        <span className="text-sm font-normal">{item.user.name}</span>
      </CardFooter>
    </Card>
  );
};

export default CardBlog;
