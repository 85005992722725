import FullLayoutHome from "layout/FullLayoutHome";
import { DataValueWeLive } from "./ComponentsPage/DefaultData";

const AboutUs = () => {
  return (
    <FullLayoutHome>
      <div className="z-10 relative">
        <div className="mt-2 mb-12">
          <div className="mx-auto container max-w-7xl py-12 px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl lg:text-5xl text-center lg:text-left font-bold tracking-wide text-gray-900 lg:col-span-2 xl:col-auto !leading-tight">
                Making Loan Search More Simple and Transparent
              </h1>
              <div className="hidden lg:block mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg text-black-primary">
                  Loanly is an online platform for you to find the best loan
                  with one application - free of charge and with no hidden fees.
                  <br />
                  <br />
                  Unlike other traditional loan comparision website, we guide
                  you from the start of your application to the time you receive
                  the loan from the financial institution of your choice
                </p>
              </div>
              <img
                src="/assets/loansearch.png"
                alt="loanly-search"
                className="mx-auto mt-6 lg:mt-0 w-full max-w-[200px] lg:max-w-[350px] rounded-2xl object-cover xl:row-span-2 xl:row-end-2"
              />
              <div className="block lg:hidden mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg text-black-primary">
                  Loanly is an online platform for you to find the best loan
                  with one application - free of charge and with no hidden fees.
                  <br />
                  <br />
                  Unlike other traditional loan comparision website, we guide
                  you from the start of your application to the time you receive
                  the loan from the financial institution of your choice
                </p>
              </div>
            </div>
          </div>
          <div className="px-6 py-12 lg:px-8 bg-primary-sec relative mt-12">
            <div className="absolute right-0 top-0 z-0">
              <img
                className="h-[280px] w-auto"
                src="/assets/elipse-top-right.png"
                alt=""
              />
            </div>
            <div className="absolute left-0 bottom-0 z-0">
              <img
                className="h-[200px] w-auto"
                src="/assets/elipse-footer-bottom-left.png"
                alt=""
              />
            </div>
            <div className="max-w-7xl mx-auto relative z-10">
              <div className="mx-auto max-w-2xl text-center flex flex-col gap-4">
                <h2 className="text-4xl lg:text-5xl font-semibold text-slate-900 dark:text-slate-200 text-white">
                  Values we Live by
                </h2>
                <p className="text-lg text-white">
                  Loanly’s core company values are at the centre of everything
                  we do - and truly reflect how we work together, serve our
                  members and build our product.
                </p>
              </div>
              <div className="flex flex-col lg:flex-row justify-between gap-4 my-10 mx-auto max-w-6xl">
                {DataValueWeLive.map((item, idx) => {
                  return (
                    <div
                      className="bg-white rounded-2xl py-4 px-6 relative"
                      key={idx}
                    >
                      <div className="flex flex-col mt-[60px] gap-3">
                        <span className="font-semibold text-xl text-primary w-[100px]">
                          {item.title}
                        </span>
                        <span>{item.description}</span>
                      </div>
                      <img
                        className="h-[70px] w-[70px] absolute top-[25px] right-[20px]"
                        src={item.image}
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-6 py-20 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:gap-x-8">
              <div className="flex flex-col justify-center items-center py-4">
                <div className="block lg:hidden">
                  <div className="flex flex-row items-center gap-2 mb-6">
                    <h1 className="text-3xl font-bold tracking-wide text-gray-900 !leading-tight flex-1">
                      We Use
                    </h1>
                    <img
                      className="h-[30px] w-auto mt-3 flex-1"
                      src="/assets/singpass.png"
                      alt=""
                    />
                  </div>
                </div>
                <img
                  src="/assets/fingerprint.png"
                  alt="loanly-fingerprint"
                  className="w-[200px] h-[200px] lg:w-[250px] lg:h-[250px] rounded-2xl object-cover xl:col-span-1"
                />
              </div>
              <div className="lg:col-span-2 xl:col-auto flex flex-col justify-center">
                <div className="w-[400px] mx-auto">
                  <div className="hidden lg:block">
                    <div className="flex flex-row items-center ">
                      <h1 className=" text-5xl font-semibold tracking-wide text-gray-900 !leading-tight flex-1">
                        We Use
                      </h1>
                      <img
                        className="h-[38px] w-auto mt-5 flex-1"
                        src="/assets/singpass.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="mt-6 max-w-xl">
                    <p className="text-lg text-black">
                      The Singapore Goverment has authorised Loanly to use
                      Singpass after strict and through checks
                      <br />
                      <br />
                      Using Singpass with Loanly is quick, easy and secure
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto max-w-2xl grid gap-y-8 lg:max-w-4xl lg:grid-cols-3 lg:gap-x-16 lg:gap-y-6 xl:gap-x-8 mt-[60px]">
              <div className="flex flex-col gap-3">
                <span className="font-semibold text-xl text-primary">
                  Our Accomplishments
                </span>
                <span>
                  As they say, change is the only constant. Throughout our first
                  decade, we’ve constantly developed innovative ideas and new
                  ways of thinking and doing in order to stay relevant.
                  <br />
                  <br /> Today, we are proud of what we’ve achieved thus far.
                  From business success to the number of people we’ve helped
                  along the way, we’re excited to see what the future has in
                  store for us.
                </span>
              </div>
              <div className="flex flex-col gap-3">
                <span className="font-semibold text-xl text-primary">
                  Our Mission
                </span>
                <span>
                  With a dedicated and professional team, we are constantly
                  pushing boundaries, improving our technology and innovating.
                  We want to provide our customers with the most updated and
                  best financial solutions. <br />
                  <br /> We aim to be the top money lender in Singapore. Our
                  company firmly believes that everyone should have equal access
                  to credit during financial emergencies.
                </span>
              </div>
              <div className="flex flex-col gap-3">
                <span className="font-semibold text-xl text-primary">
                  What We Offer
                </span>
                <ul className="list-disc pl-4 flex flex-col gap-3">
                  <li>Easy-to-use online application form</li>
                  <li>Fuss-free loan application</li>
                  <li>Transparent and fair money lender</li>
                  <li>
                    Online system to check on loan status anytime, anywhere
                  </li>
                  <li>Quick replies, best for financial emergencies</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullLayoutHome>
  );
};

export default AboutUs;
