import * as Yup from "yup";
import moment from "moment";

const StepFirst = Yup.object().shape({
  loan_amount: Yup.number()
    .min(1000, "Loan amount must be at least $1,000")
    .max(250000, "Loan amount cannot exceed $250,000")
    .required("Please fill field Loan amount"),
  tenor_id: Yup.number().required("Please select Tenor first"),
  main_purpose_id: Yup.number().required("Please select Purpose first"),
  full_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your Full name"),
  residency_id: Yup.number().required("Please select Residency first"),
  nationality_id: Yup.number().required("Please select Nationality first"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email address"),
  phone_number: Yup.string()
    .matches(/^[89]/, "Phone number must start with 8 or 9")
    .min(8, "Phone number must be 8 digits")
    .max(14, "Phone number can have a maximum of 14 digits")
    .required("Please enter your phone number"),
  employment_status_id: Yup.number().required(
    "Please select Employment status first"
  ),
  monthly_income: Yup.number().required("Please fill field Monthly income"),
  day: Yup.number()
    .max(32, "Please enter valid day")
    .required("Please fill field Day"),
  month: Yup.number()
    .max(12, "Please enter valid month")
    .required("Please fill field Month"),
  year: Yup.number()
    .required("Please fill field Years")
    .test("valid-age", "You must be at least 18 years old.", function (value) {
      const { day, month, year } = this.parent;
      const birthday = moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
      const age = moment().diff(birthday, "years");
      return age >= 18;
    })
    .test(
      "valid-age-max",
      "You must be at most 80 years old.",
      function (value) {
        const { day, month, year } = this.parent;
        const birthday = moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
        const age = moment().diff(birthday, "years");
        return age <= 80;
      }
    ),
});

const Step2 = Yup.object().shape({
  nric_fin: Yup.string()
    .min(9, "NRIC number must be 9 digits")
    .required("Please enter your NRIC/FIN"),
  civil_status_id: Yup.number().required("Please select Civil status first"),
  gender: Yup.string().required("Please enter your Gender first"),
  job_title_id: Yup.number().required("Please select Title Job first"),
  job_industry_id: Yup.number().required("Please select Job Industry first"),
  length_with_current_employer_id: Yup.number().required(
    "Please fill field length with current employer"
  ),
  length_with_previous_employer_id: Yup.number().required(
    "Please fill field length with previous employer"
  ),
  address: Yup.string().required("Please enter your Address first"),
  unit: Yup.string().required("Please enter your Unit first"),
  zip_code: Yup.string().required("Please enter your Zip Code first"),
  housing_type_id: Yup.number().required("Please select House Type first"),
  housing_status_id: Yup.number().required("Please select House Status first"),
  length_of_stay_year_id: Yup.number().required(
    "Please select Length of stay year first"
  ),
  is_own_property: Yup.boolean().required("Please select Own property first"),
});

const Step3 = Yup.object().shape({
  existing_loan_amount_bank: Yup.number().required(
    "Please fill field existing loan amount Bank, if you don't have put $0"
  ),
  existing_loan_amount_moneylender: Yup.number().required(
    "Please fill field existing loan amount Moneylender, if you don't have put $0"
  ),
  monthly_repayment_bank: Yup.number().required(
    "Please fill field Monthly income, if you don't have put $0"
  ),
  monthly_repayment_moneylender: Yup.number().required(
    "Please fill field Monthly income, if you don't have put $0"
  ),
});

const Step4 = Yup.object().shape({
  employment_status_id: Yup.number().required(
    "Please select Employment status first"
  ),
  monthly_income: Yup.number().required("Please fill field Monthly income"),
});

const Step5 = Yup.object().shape({
  nric_fin: Yup.string().required("Please enter your NRIC/FIN"),
  civil_status_id: Yup.number().required("Please select Civil status first"),
});

const Step6 = Yup.object().shape({
  job_title_id: Yup.number().required("Please select Title Job first"),
  job_industry_id: Yup.number().required("Please select Job Industry first"),
  length_with_current_employer_id: Yup.number().required(
    "Please fill field length with current employer"
  ),
  length_with_previous_employer_id: Yup.number().required(
    "Please fill field length with previous employer"
  ),
});

const Step7 = Yup.object().shape({
  address: Yup.string().required("Please enter your Address first"),
  unit: Yup.string().required("Please enter your Unit first"),
  zip_code: Yup.string().required("Please enter your Zip Code first"),
  housing_type_id: Yup.number().required("Please select House Type first"),
  housing_status_id: Yup.number().required("Please select House Status first"),
  length_of_stay_year_id: Yup.number().required(
    "Please select Length of stay year first"
  ),
  is_own_property: Yup.boolean().required("Please select Own property first"),
});

const Step8 = Yup.object().shape({
  existing_loan_amount_bank: Yup.number().required(
    "Please fill field existing loan amount Bank, if you don't have put $0"
  ),
  existing_loan_amount_moneylender: Yup.number().required(
    "Please fill field existing loan amount Moneylender, if you don't have put $0"
  ),
  monthly_repayment_bank: Yup.number().required(
    "Please fill field Monthly income, if you don't have put $0"
  ),
  monthly_repayment_moneylender: Yup.number().required(
    "Please fill field Monthly income, if you don't have put $0"
  ),
});

export { StepFirst, Step2, Step3, Step4, Step5, Step6, Step7, Step8 };
