import React from "react";
import { NumericFormat } from "react-number-format";
import { Input } from "@material-tailwind/react";
import { useField } from "formik";

const InputNumberComponent = ({
  label,
  required,
  setFieldValue,
  disabled,
  type = "default",
  ...props
}) => {
  const [field, meta] = useField(props);
  const InputPriceProp = {
    label: label,
    className: `px-3.5 bg-white font-semibold !text-black`,
    color: "blue",
    size: "lg",
    error: Boolean(meta.touched && meta.error),
    ...field,
  };

  if (type === "handle-zero") {
    return (
      <div>
        <NumericFormat
          {...InputPriceProp}
          name={field.name}
          customInput={Input}
          prefix={"$"}
          thousandSeparator=","
          value={field.value}
          onChange={(e) => console.log(e.target.value)}
          onValueChange={(val) => {
            setFieldValue(field.name, val.floatValue);
          }}
        />
        {meta.touched && meta.error && (
          <div className="text-red-600 mt-1 text-sm">{meta.error}</div>
        )}
      </div>
    );
  }

  return (
    <div>
      <NumericFormat
        {...InputPriceProp}
        name={field.name}
        customInput={Input}
        prefix={"$"}
        thousandSeparator=","
        value={field.value}
        onChange={(e) => console.log(e.target.value)}
        onValueChange={(val) => {
          setFieldValue(field.name, val.floatValue || "");
        }}
      />
      {meta.touched && meta.error && (
        <div className="text-red-600 mt-1 text-sm">{meta.error}</div>
      )}
    </div>
  );
};

export default InputNumberComponent;
