import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Button } from "@material-tailwind/react";
import { IconX, IconMenu2 } from "@tabler/icons-react";
import { LinkPageData } from "./DataMenu";

const navigation = LinkPageData;
const URL_BROWSER = process.env.REACT_APP_URL_BROWSER;

const HeaderPage = ({ isSticky }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const goToLogin = () => {
    window.location.href = `${URL_BROWSER}/login`;
  };

  return (
    <header className="relative">
      <nav
        className={`mx-auto flex max-w-7xl items-center justify-between px-6 py-5 lg:px-8`}
        aria-label="Global"
      >
        <div className="flex items-center gap-x-12">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              className="h-10 w-auto"
              src={`${
                isSticky ? "/assets/loanly_white.webp" : "/assets/loanly.webp"
              } `}
              alt=""
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${
              isSticky ? "text-white" : "text-primary"
            } `}
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <IconMenu2 className="h-7 w-7" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex items-center gap-x-12">
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => {
              if (item.footer) return <div key={item.name} />;

              return (
                <a
                  key={item.name}
                  href={item.href}
                  className={`text-base leading-6 ${
                    isSticky ? "text-white" : "text-gray-900"
                  }  ${
                    item.href === location.pathname
                      ? "font-semibold"
                      : "font-normal"
                  }`}
                >
                  {item.name}
                </a>
              );
            })}
          </div>
          <Button
            size="md"
            onClick={() => goToLogin()}
            className={`${
              isSticky ? "bg-white text-primary" : "bg-primary text-white"
            }  flex items-center py-2 font-poppins font-medium rounded-lg tracking-wide shadow-none`}
          >
            LOGIN
          </Button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-30" />
        <Dialog.Panel className="fixed top-0 left-0 right-0 z-30 w-full overflow-y-auto bg-primary px-6 py-5 pb-12 rounded-br-[80px]">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-10 w-auto"
                src="/assets/loanly_white.webp"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <IconX className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-white">
              <div className="space-y-2 py-6">
                {navigation.map((item) => {
                  if (item.footer) {
                    return <div key={item.name} />;
                  }

                  return (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-primary focus:bg-transparent focus:text-white"
                    >
                      {item.name}
                    </a>
                  );
                })}
              </div>
              <div>
                <Button
                  size="lg"
                  onClick={() => goToLogin()}
                  className="mt-6 bg-white text-primary flex items-center py-2 font-poppins font-medium rounded-lg tracking-wide shadow-none"
                >
                  LOGIN
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default HeaderPage;
